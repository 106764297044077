import { Component, Vue } from 'vue-property-decorator'

//components
import PortalAdminContent from '@/components/portalAdminContent2/PortalAdminContent2.vue'

// services
import { getAllMapTiposDoc } from '@/services/documentosService'
  
@Component({
  components: {
    PortalAdminContent
  }
})
export default class CategoriaDocumentos extends Vue {

  arrTiposDocs = getAllMapTiposDoc()
  
}
  